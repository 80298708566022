<template>
  <div class="container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="结算列表" name="settle">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="类型">
            <el-select v-model="settle_type">
              <el-option label="海马课包结算" value="结算"></el-option>
              <el-option label="教练需求结算" value="教练需求结算"></el-option>
              <el-option label="机构需求结算" value="机构需求结算"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期">
            <el-date-picker
              v-model="settle_timeDate"
              value-format="yyyy-MM-dd hh:mm:ss"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getSettleList(1)">查询</el-button>
          </el-form-item>
        </el-form>
        <auto-table
          :DataList="settleDataList"
          :option="settleOption"
          :total="settleTotal"
          @changePage="getSettleList"
        >
          <template #finished="{ row }">
            <div v-if="row.finished" style="color: green">已支付</div>
            <div v-else>未支付</div>
          </template>
        </auto-table>
      </el-tab-pane>
      <el-tab-pane label="退款列表" name="refund">
        <el-form :inline="true">
          <el-form-item label="类型">
            <el-select v-model="refund_type" clearable>
              <el-option label="课程退款" value="退课"></el-option>
              <el-option label="消费金退款" value="consume_refund"></el-option>
              <el-option label="场馆退款" value="venue_refund"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期">
            <el-date-picker
              v-model="refund_timeDate"
              value-format="yyyy-MM-dd hh:mm:ss"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getrefundList(1)">查询</el-button>
          </el-form-item>
        </el-form>
        <auto-table
          :DataList="refundDataList"
          :option="refundOption"
          :total="refundTotal"
          @changePage="getrefundList"
        >
          <template #type="{ row }">
            <div>
              <p v-if="row.type == '退课'">课程退款</p>
              <p v-if="row.type == 'consume_refund'">消费金退款</p>
              <p v-if="row.type == 'venue_refund'">场馆退款</p>
            </div>
          </template>
        </auto-table>
      </el-tab-pane>
      <!--  -->
      <el-tab-pane label="提现列表" name="cash">
         <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="日期">
            <el-date-picker
              v-model="cash_timeDate"
              value-format="yyyy-MM-dd hh:mm:ss"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getCashList(1)">查询</el-button>
          </el-form-item>
        </el-form>
        <auto-table
          :DataList="cashDataList"
          :option="cashOption"
          :total="cashTotal"
          @changePage="getCashList"
        >
         <template #rcharge_type >
          <p >提现</p>
          </template>
         <template #finished="{ row }">
            <div v-if="row.finished" style="color: green">已支付</div>
            <div v-else>未支付</div>
          </template>
        </auto-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "settle",
      //settle
      settleDataList: [],
      settleOption: [
        { name: "流水号", value: "flowing_no" },
        { name: "金额", value: "amount" },
        { name: "类型", value: "rcharge_type" },
        { name: "备注", value: "rcharge_abstract" },
        { name: "状态", value: "finished", type: "custom" },
        { name: "创建时间", value: "create_time" },
      ],
      settleTotal: 0,
      settle_type: "结算",
      settle_timeDate: [],
      //refund
      refund_type: "",
      refund_timeDate: [],
      refundDataList: [],
      refundOption: [
        { name: "金额(元)", value: "amount" },
         { name: "说明", value: "reason" },
        { name: "类型", value: "type" },
        { name: "时间", value: "create_time" },
      ],
      refundTotal: 0,
      //cash
      cash_timeDate: [],
      cashDataList: [],
      cashOption: [
          { name: "流水号", value: "flowing_no" },
        { name: "金额", value: "amount" },
        { name: "类型", value: "rcharge_type",type: "custom" },
        { name: "备注", value: "rcharge_abstract" },
        { name: "状态", value: "finished", type: "custom" },
        { name: "创建时间", value: "create_time" },
      ],
      cashTotal: 0,
    };
  },

  mounted() {
    this.getSettleList();
  },

  methods: {
    handleClick(v) {
      switch (v.name) {
        case "settle":
          this.getSettleList();
          break;
        case "refund":
          this.getrefundList();
          break;
        case "cash":
          this.getCashList();
          break;
      }
    },
    // 结算
    getSettleList(v) {
      let page = v || 1;
      this.settle_timeDate == null && (this.settle_timeDate = []);
      this.$axios({
        url: "/user/rechargerecord/queryByMessage",
        params: {
          currentPage: page,
          pageSize: 10,
          finished: 1,
          rcharge_type: this.settle_type,
          start_time: this.settle_timeDate[0] || null,
          end_time: this.settle_timeDate[1] || null,
        },
      }).then((res) => {
        this.settleDataList = res.data.data.rows;
        this.settleTotal = res.data.data.total;
      });
    },
    //分成记录
    getrefundList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/rechargerecordrefund/queryByMessage",
        params: {
          currentPage: page,
          pageSize: 10,
          status: 3,
          type: this.refund_type || null,
          start_time: this.refund_timeDate[0] || null,
          end_time: this.refund_timeDate[1] || null,
        },
      }).then((res) => {
        this.refundDataList = res.data.data.rows;
        this.refundTotal = res.data.data.total;
      });
    },
    //提现记录
    getCashList(v) {
      let page = v || 1;
      this.$axios({
          url: "/user/rechargerecord/queryByMessage",
        params: {
          currentPage: page,
          pageSize: 10,
          finished: 1,
          rcharge_type: 'transfer',
          start_time: this.cash_timeDate[0] || null,
          end_time: this.cash_timeDate[1] || null,
        },
      }).then((res) => {
        this.cashDataList = res.data.data.rows;
        this.cashTotal = res.data.data.total;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>